import { gql } from "@apollo/client"

const MUTATION_CREATE_CHECKLIST = gql`
  mutation createChecklist(
    $name: String
    $intake_response: IntakeResponseInput!
  ) {
    createChecklist(input: { name: $name, intake_response: $intake_response })  {
      id
      name
      intake_response {
        id
        plan_id
        scenario
      }
      created_at
      updated_at
      abilities {
        delete
      }
    }
  }
`

const MUTATION_UPDATE_CHECKLIST = gql`
  mutation updateChecklist(
    $id: ID!
    $name: String!
    $isPreplanningPlan: Boolean!
  ) {
    updateChecklist(
      id: $id
      name: $name
      isPreplanningPlan: $isPreplanningPlan
    ) {
      id
      name
    }
  }
`

const MUTATION_ARCHIVE_CHECKLIST = gql`
  mutation archiveChecklist($id: ID!) {
    archiveChecklist(id: $id) {
      id
    }
  }
`

const MUTATION_CUSTOMIZE_CHECKLIST = gql`
  mutation customizeChecklist($id: ID!, $funeralDecision: String) {
    customizeChecklist(id: $id, funeralDecision: $funeralDecision) {
      message
      status
    }
  }
`

const MUTATION_TOGGLE_PLAN_ONBOARDED = gql`
  mutation updateOnboarded($plan_id: ID!) {
    updateOnboarded(plan_id: $plan_id) {
      id
    }
  }
`

const MUTATION_REQUEST_CONSULT = gql`
  mutation requestConsult {
    requestConsult
  }
`

export {
  MUTATION_CREATE_CHECKLIST,
  MUTATION_UPDATE_CHECKLIST,
  MUTATION_ARCHIVE_CHECKLIST,
  MUTATION_CUSTOMIZE_CHECKLIST,
  MUTATION_TOGGLE_PLAN_ONBOARDED,
  MUTATION_REQUEST_CONSULT,
}
