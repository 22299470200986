import { gql } from "@apollo/client"

const QUERY_GET_PAYMENT_METHODS = gql`
  query getPaymentMethods {
    getPaymentMethods {
      id
      card {
        brand
        country
        exp_month
        exp_year
        last4
      }
      billing_details {
        address {
          postal_code
        }
      }
      is_default
    }
  }
`

const QUERY_GET_PRICE_DETAILS = gql`
  query getPriceDetails(
    $priceId: String!
    $coupon: String
    $address: AddressInput
  ) {
    getPriceDetails(priceId: $priceId, coupon: $coupon, address: $address) {
      name
      priceId
      originalPrice
      finalPrice
      discount
      tax
    }
  }
`

export { QUERY_GET_PAYMENT_METHODS, QUERY_GET_PRICE_DETAILS }
