import React from "react"
import PropTypes from "prop-types"

const Header = ({
  text,
  text_mobile,
  buttonText,
  showHandler,
  handler,
  type,
  additionalClasses,
  showOutOfApp,
}) => {
  switch (type) {
    case "page":
      return (
        <>
          <h1
            className={`text-4xl md:text-5xl font-extrabold w-full leading-tight md:hidden 
            ${additionalClasses} ${showOutOfApp ? "mb-4" : "pt-12 pb-8"}`}
          >
            {text_mobile}
          </h1>
          <h1
            className={`text-4xl md:text-5xl font-extrabold w-full leading-tight hidden md:block
            ${additionalClasses} ${showOutOfApp ? "mb-4" : "pt-12 pb-8"}`}
          >
            {text}
          </h1>
        </>
      )
    case "section":
      return (
        <div className="flex flex-col">
          <div className="flex flex-row w-full">
            <h2 className="text-2xl md:text-3xl font-extrabold w-full pt-12 pb-4 leading-normal md:hidden">
              {text_mobile}
            </h2>
            <h2 className="text-2xl md:text-3xl font-extrabold w-full pt-12 pb-4 leading-normal hidden md:block">
              {text}
            </h2>

            {showHandler && (
              <div className="w-1/2 flex justify-end pt-12 pb-4 md:pt-16">
                <button
                  onClick={handler}
                  className="sidebar-text text-primary focus:outline-none"
                >
                  {buttonText}
                </button>
              </div>
            )}
          </div>
        </div>
      )
    case "modal":
      return (
        <h1 className={`modal-headline-text w-full pb-4 ${additionalClasses}`}>
          {text}
        </h1>
      )
    case "question":
      return (
        <h1 className="text-3xl md:text-5xl leading-tight font-extrabold mt-3 mb-2 md:mb-3">
          {text}
        </h1>
      )
    default:
      return null
  }
}

Header.propTypes = {
  text: PropTypes.string,
  text_mobile: PropTypes.string,
  buttonText: PropTypes.string,
  showHandler: PropTypes.bool,
  handler: PropTypes.func,
  type: PropTypes.string,
  additionalClasses: PropTypes.string,
  showOutOfApp: PropTypes.bool,
}

Header.defaultProps = {
  text: "",
  text_mobile: "",
  buttonText: "",
  showHandler: false,
  handler: () => {},
  type: "",
  additionalClasses: "",
  showOutOfApp: false,
}

export default Header
