import React, { useState } from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import Newsletter from "../components/homepage/newsletter"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { linkResolver } from "../utils/link-resolver"
import { useMutation } from "@apollo/client"
import { MUTATION_REQUEST_CONSULT } from "../graphql/mutations/checklist"
import ConfirmModal from "../components/modals/confirm-modal"
import SlicesContainer from "./slices"
import SEO from "../components/seo"
import { seoKeywords } from "../utils/helpers";

function ContentPageTemplate({ pageContext }) {
  const content = pageContext?.content
  const showNewsletter = content?.show_newsletter
  const [isConsultRequested, setIsConsultRequested] = useState(false)
  const [requestConsult] = useMutation(MUTATION_REQUEST_CONSULT)
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const requestAConsult = () => setShowConfirmModal(true)

  return (
    <Layout>
      <SEO
        keywords={[
          seoKeywords(content?.seo_keywords?.text)
        ]}
        title={content?.seo_title?.text}
        description={content?.seo_description?.text}
        excludeSiteMetadataTitle
      />

      <SlicesContainer 
        content={content?.body} 
        uid={pageContext?.uid}
        isConsultRequested={isConsultRequested}
        requestAConsult={requestAConsult}
      />

      {showNewsletter && (
        <Newsletter location={`${pageContext?.uid} - Content Page Newsletter`} />
      )}

      {showConfirmModal && (
        <ConfirmModal
          closeModal={() => setShowConfirmModal(false)}
          title="Request a Lantern Guide Consult"
          description={`A Lantern Guide is a real person who provides 1-on-1 support for you as you wade through the logistics of managing a loss.\n
            After requesting a consult, your Guide will reach out to schedule a free initial conversation to learn more about your situation and how they can help.\n
            Request your consult by clicking the button below.`}
          confirmButtonText={`${
            isConsultRequested ? "Request Sent" : "Send Request"
          }`}
          cancelButtonText="Cancel"
          confirmButtonDisabled={isConsultRequested}
          onConfirm={() => {
            requestConsult().then(() => {
              setIsConsultRequested(true)
            })
          }}
          onCancel={() => setShowConfirmModal(false)}
        />
      )}
    </Layout>
  )
}

ContentPageTemplate.propTypes = {
  pageContext: PropTypes.object,
  uid: PropTypes.string,
}

export default withPrismicPreview(ContentPageTemplate, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])
