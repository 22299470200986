exports.linkResolver = (doc) => {
  switch (doc.type) {
    case "about":
      return "/about"
    case "homepage":
      return "/"
    case "article":
      return `/articles/${doc.uid}`
    case "contact":
      return `/${doc.uid}`
    case "shop":
      return "/shop"
    case "advance_directive_template":
      return doc.uid === "advance-directive-forms"
        ? `/${doc.uid}`
        : `advance-directive-forms/${doc.uid}`
    case "funeralocity":
      return "/funeralocity"
    default:
      return `/${doc.uid}`
  }
}
