const getValidationErrors = (error) => {
  const messages = []

  if (error?.graphQLErrors && error?.graphQLErrors.length > 0) {
    const extensions = error?.graphQLErrors[0]?.extensions

    if (extensions && "validation" in extensions) {
      Object.entries(extensions.validation).map(([, value]) => {
        messages.push(value[0])
        return value
      })
    }
  }
  return messages
}

export { getValidationErrors }
