import React from "react"
import { useQuery } from "@apollo/client"
import { QUERY_GET_PRICE_DETAILS } from "../graphql/queries/payment"

const PricingContext = React.createContext()

function PricingProvider(props) {
  const { data: premiumLifetime } = useQuery(QUERY_GET_PRICE_DETAILS, {
    variables: {
      priceId: process.env.GATSBY_PRE_PLANNING_PREMIUM_PLAN_YEARLY_PRICE_ID,
    },
  })

  const { data: preplanningPDF } = useQuery(QUERY_GET_PRICE_DETAILS, {
    variables: {
      priceId: process.env.GATSBY_PRE_PLANNING_PDF_PRICE_ID,
    },
  })

  return (
    <PricingContext.Provider
      value={{
        premiumLifetime,
        preplanningPDF,
      }}
      {...props}
    />
  )
}

const usePricing = () => React.useContext(PricingContext)

export { PricingProvider, usePricing }
